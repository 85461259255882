<template>
  <div v-if="voucher">
    <modal-edit-profiles
      v-if="showEditProfiles"
      :showModal="showEditProfiles"
      :onClickCancel="hideModal"
    ></modal-edit-profiles>

    <ui-section-header :showEdit="true" :editFunction="openProfiles">
      <template v-slot:title>{{
        $t('Components.Vouchers.Profiles.Template_Title')
      }}</template>
    </ui-section-header>

    <message :message="$t('Components.Vouchers.Profiles.Error_Text')" />

    <table class="table is-striped is-fullwidth">
      <tbody>
        <tr v-for="profile in voucher.Profiles" :key="profile.Id">
          <td>{{ profile.ProfileName }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Message from '@/components/UI/Message'

const ModalEditProfiles = () =>
  import('@/components/Vouchers/ModalEditProfiles')

export default {
  components: {
    message: Message,
    'modal-edit-profiles': ModalEditProfiles
  },

  data() {
    return {
      showEditProfiles: false
    }
  },

  computed: {
    ...mapState('voucherStore', ['voucher'])
  },

  beforeCreate() {},

  created() {},

  methods: {
    openProfiles() {
      this.showEditProfiles = true
    },
    hideModal() {
      this.showEditProfiles = false
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  tbody {
    tr {
      &:first-child {
        td {
          border-top-width: 1px;
        }
      }
      td {
      }
    }
  }
}
</style>
