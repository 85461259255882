<template>
  <div>
    <ModalEditText
      v-if="showEditText"
      :showModal="showEditText"
      :onClickCancel="hideModal"
      :channelId="channelId"
      :locationId="locationId"
    />

    <ui-section-header
      :showEdit="locationCanEdit"
      :editFunction="setShowEditText"
    >
      <template v-slot:title>{{
        $t('Components.Vouchers.Texts.Subtitle_PublicTexts')
      }}</template>
    </ui-section-header>

    <Message :message="$t('Components.Vouchers.Texts.Message_PublicTexts')" />

    <div v-if="voucher.Descriptions.length > 0" class="columns is-multiline">
      <div
        v-for="(textLanguage, index) in voucher.Descriptions"
        :key="index"
        class="column is-half"
      >
        <div class="title is-5">
          {{ $t('LanguageLabels.Language' + textLanguage.LanguageId) }}
        </div>

        <div class="textWrapper has-padding">
          <div class="">
            <strong>{{ $t('Components.Vouchers.Texts.Label_Name') }}:</strong
            ><br />
            {{ textLanguage.Name }}
          </div>

          <div class="is-divider"></div>

          <div class="has-margin-bottom has-text-weight-bold">
            {{ $t('Components.Vouchers.Texts.Label_Description') }}:
          </div>
          <div class="content" v-html="textLanguage.Text"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const ModalEditText = () => import('@/components/Vouchers/ModalEditText')
const Message = () => import('@/components/UI/Message')

export default {
  components: {
    ModalEditText,
    Message,
  },

  props: {},

  data() {
    return {
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      showEditText: false,
    }
  },

  computed: {
    ...mapState('voucherStore', ['voucher']),

    locationCanEdit() {
      let canEdit = false

      if (this.voucher && this.voucher.ChannelId === this.channelId) {
        canEdit = true
      }

      if (this.voucher && this.channelId === 0) {
        let locationIndex = this.voucher.Locations.findIndex(
          (l) => l.LocationId === this.locationId
        )
        if (locationIndex > -1) {
          canEdit = this.voucher.Locations[locationIndex].LocationCreated
        }
      }

      return canEdit
    },
  },

  created() {
    if (this.locationId > 0) {
      this.channelId = 0
    }
  },

  methods: {
    setShowEditText() {
      this.showEditText = true
    },
    hideModal() {
      this.showEditText = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/template';
.textWrapper {
  border: 1px solid $grey-lighter;
  overflow-y: scroll;
  width: 100%;
  height: 300px;
}
</style>
