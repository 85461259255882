<template>
  <div>
    <ModalSetSpacePrice
      v-if="showModalSpacePrice"
      :showModal="showModalSpacePrice"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showAdd="locationCanEdit && yieldSettings === null"
      :addFunction="setShowModalSpacePrice"
      :showEdit="locationCanEdit && yieldSettings !== null"
      :editFunction="setShowModalSpacePrice"
    >
      <template v-slot:title>Space price</template>
    </ui-section-header>

    <table v-if="yieldSettings" class="table is-fullwidth is-striped">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>VAT</th>
          <th class="has-text-right">Price</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Space price</td>
          <td>{{ taxPercentage }}%</td>
          <td class="has-text-right">{{ price | toCurrency }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const ModalSetSpacePrice = () =>
  import('@/components/Vouchers/ModalSetSpacePrice')

export default {
  components: {
    ModalSetSpacePrice,
  },

  props: {},

  data() {
    return {
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      showModalSpacePrice: false,
    }
  },

  computed: {
    ...mapState('voucherStore', ['voucher']),

    locationCanEdit() {
      let canEdit = false

      if (this.voucher && this.voucher.ChannelId === this.channelId) {
        canEdit = true
      }

      if (this.voucher && this.channelId === 0) {
        let locationIndex = this.voucher.Locations.findIndex(
          (l) => l.LocationId === this.locationId
        )
        if (locationIndex > -1) {
          canEdit = this.voucher.Locations[locationIndex].LocationCreated
        }
      }

      return canEdit
    },

    yieldSettings() {
      let settings = null

      if (
        this.voucher &&
        this.voucher.Prices &&
        this.voucher.Prices.length > 0
      ) {
        settings = this.voucher.Prices[0]
      }

      return settings
    },

    price() {
      let price = 0

      if (this.yieldSettings && this.yieldSettings.PriceParts) {
        price = this.yieldSettings.PriceParts[0].Price
      }

      return price
    },

    taxPercentage() {
      let taxPercentage = 0

      if (this.yieldSettings) {
        taxPercentage = this.yieldSettings.TaxPercentage
      }

      return taxPercentage
    },
  },

  created() {
    if (this.locationId > 0) {
      this.channelId = 0
    }
  },

  methods: {
    setShowModalSpacePrice() {
      this.showModalSpacePrice = true
    },

    hideModal() {
      this.showModalSpacePrice = false
    },
  },
}
</script>

<style></style>
