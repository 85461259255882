<template>
  <div v-if="mVoucher">
    <modal-edit-settings
      v-if="showEditsettings"
      :voucher="voucher"
      :showModal="showEditsettings"
      :onClickCancel="hideModal"
    ></modal-edit-settings>

    <ui-section-header
      v-if="!hideSectionHeader"
      :showEdit="true"
      :editFunction="openSettings"
    >
      <template v-slot:title>{{
        $t('Components.Vouchers.Settings.Section_Title')
      }}</template>
    </ui-section-header>
    <message
      :type="'is-info'"
      :message="$t('Components.Vouchers.Settings.Error_Text')"
    ></message>

    <div class="columns is-multiline">
      <div class="column">
        <table class="table is-striped is-fullwidth">
          <tbody>
            <tr v-if="voucher.Type === 'Action'">
              <td width="50%">
                {{
                  $t('Components.Vouchers.Settings.Label_VoucherOncePerPerson')
                }}
              </td>
              <td>{{ $t(`General.Yes`) }}</td>
            </tr>
            <tr v-if="voucher.Type === 'Credit'">
              <td>{{ $t('Components.Vouchers.Settings.Label_Credit') }}</td>
              <td
                v-html="locationState.CurrencySymbol + ' ' + voucher.Credit"
              ></td>
            </tr>
            <tr v-if="voucher.Type === 'Voucher'">
              <td>
                {{ $t('Components.Vouchers.Settings.Label_SeatsAmount') }}
              </td>
              <td>{{ voucher.SeatsCount }}</td>
            </tr>
            <tr>
              <td>
                {{ $t('Components.Vouchers.Settings.Label_UsedWhenBooking') }}
              </td>
              <td>
                <div v-if="meetingtypes">
                  <div
                    v-for="meetingtype in meetingtypes"
                    :key="meetingtype.MeetingtypeId"
                  >
                    <span class="main"
                      >{{
                        meetingtype.MeetingtypeId | getMeetingtypeName
                      }}:</span
                    >
                    <span class="icon has-padding-left">
                      <font-awesome-icon
                        v-if="
                          checkMeetingtypeInVoucher(meetingtype.MeetingtypeId)
                        "
                        :icon="['fas', 'check']"
                        class="has-text-success"
                      />
                      <font-awesome-icon
                        v-if="
                          !checkMeetingtypeInVoucher(meetingtype.MeetingtypeId)
                        "
                        :icon="['fas', 'times']"
                        class="has-text-danger"
                      />
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="column">
        <table class="table is-striped is-fullwidth">
          <tbody>
            <tr>
              <td>
                {{ $t('Components.Vouchers.Settings.Label_ValidityPeriod') }}
              </td>
              <td colspan="3">
                {{
                  voucher.ValidFrom | dateFormat($i18n.locale, 'longDateFormat')
                }}
                -
                {{
                  voucher.ValidUntil
                    | dateFormat($i18n.locale, 'longDateFormat')
                }}
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('Components.Vouchers.Settings.Label_MinimalSeats') }}
              </td>
              <td colspan="2">{{ voucher.MinSeats }}</td>
              <td>
                {{ $t('Components.Vouchers.Settings.Label_MaximalSeats') }}
              </td>
              <td colspan="2">{{ voucher.MaxSeats }}</td>
            </tr>
            <tr>
              <td>
                {{ $t('Components.Vouchers.Settings.Label_MinimalHours') }}
              </td>
              <td colspan="2">{{ voucher.MinHours }}</td>
              <td>
                {{ $t('Components.Vouchers.Settings.Label_MaximalHours') }}
              </td>
              <td colspan="2">{{ voucher.MaxHours }}</td>
            </tr>
            <tr>
              <td>{{ $t('Components.Vouchers.Settings.Label_Days') }}</td>
              <td>
                <span>{{
                  $t('Components.Vouchers.Settings.Label_Monday')
                }}</span
                ><br />
                <span>{{
                  $t('Components.Vouchers.Settings.Label_Tuesday')
                }}</span
                ><br />
                <span>{{
                  $t('Components.Vouchers.Settings.Label_Wednesday')
                }}</span
                ><br />
                <span>{{
                  $t('Components.Vouchers.Settings.Label_Thursday')
                }}</span
                ><br />
              </td>
              <td>
                <span>
                  <font-awesome-icon
                    v-if="voucherDayActive(2)"
                    :icon="['fas', 'check']"
                    class="has-text-success"
                  />
                  <font-awesome-icon
                    v-if="!voucherDayActive(1)"
                    :icon="['fas', 'times']"
                    class="has-text-danger"
                  /> </span
                ><br />
                <span>
                  <font-awesome-icon
                    v-if="voucherDayActive(3)"
                    :icon="['fas', 'check']"
                    class="has-text-success"
                  />
                  <font-awesome-icon
                    v-if="!voucherDayActive(3)"
                    :icon="['fas', 'times']"
                    class="has-text-danger"
                  /> </span
                ><br />
                <span>
                  <font-awesome-icon
                    v-if="voucherDayActive(4)"
                    :icon="['fas', 'check']"
                    class="has-text-success"
                  />
                  <font-awesome-icon
                    v-if="!voucherDayActive(4)"
                    :icon="['fas', 'times']"
                    class="has-text-danger"
                  /> </span
                ><br />
                <span>
                  <font-awesome-icon
                    v-if="voucherDayActive(5)"
                    :icon="['fas', 'check']"
                    class="has-text-success"
                  />
                  <font-awesome-icon
                    v-if="!voucherDayActive(5)"
                    :icon="['fas', 'times']"
                    class="has-text-danger"
                  /> </span
                ><br />
              </td>
              <td>
                <span>{{
                  $t('Components.Vouchers.Settings.Label_Friday')
                }}</span
                ><br />
                <span>{{
                  $t('Components.Vouchers.Settings.Label_Saturday')
                }}</span
                ><br />
                <span>{{
                  $t('Components.Vouchers.Settings.Label_Sunday')
                }}</span
                ><br />
              </td>
              <td>
                <span>
                  <font-awesome-icon
                    v-if="voucherDayActive(6)"
                    :icon="['fas', 'check']"
                    class="has-text-success"
                  />
                  <font-awesome-icon
                    v-if="!voucherDayActive(6)"
                    :icon="['fas', 'times']"
                    class="has-text-danger"
                  /> </span
                ><br />
                <span>
                  <font-awesome-icon
                    v-if="voucherDayActive(7)"
                    :icon="['fas', 'check']"
                    class="has-text-success"
                  />
                  <font-awesome-icon
                    v-if="!voucherDayActive(7)"
                    :icon="['fas', 'times']"
                    class="has-text-danger"
                  /> </span
                ><br />
                <span>
                  <font-awesome-icon
                    v-if="voucherDayActive(1)"
                    :icon="['fas', 'check']"
                    class="has-text-success"
                  />
                  <font-awesome-icon
                    v-if="!voucherDayActive(1)"
                    :icon="['fas', 'times']"
                    class="has-text-danger"
                  /> </span
                ><br />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Message from '@/components/UI/Message'

const ModalEditSettings = () =>
  import('@/components/Vouchers/ModalEditSettings')

export default {
  components: {
    message: Message,
    'modal-edit-settings': ModalEditSettings,
  },
  props: {
    voucher: {
      type: Object,
      default: null,
    },
    hideSectionHeader: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      mVoucher: this.voucher,
      showEditsettings: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationState']),

    meetingtypes() {
      let meetingtypes = []

      if (this.channel) {
        meetingtypes = this.channel.Meetingtypes
      }

      if (this.locationState) {
        meetingtypes = this.locationState.Meetingtypes
      }

      return meetingtypes
    },
  },

  beforeCreate() {},

  created() {
    // this.buildFormSchema()
    // this.buildMeetingTypesObject()
  },

  methods: {
    checkMeetingtypeInVoucher(meetingtypeId) {
      let result = false

      if (this.voucher) {
        let typeIndex = this.voucher.MeetingtypeIds.findIndex(
          (m) => m === meetingtypeId
        )
        if (typeIndex > -1) {
          result = true
        }
      }

      return result
    },

    voucherDayActive(day) {
      let dayIndex = this.voucher.Days.findIndex((d) => d === day)
      return dayIndex > -1
    },

    openSettings() {
      this.showEditsettings = true
    },
    hideModal() {
      this.showEditsettings = false
    },
  },
}
</script>

<style lang="scss" scoped>
.tables {
  display: flex;
  .table {
    &:first-child {
      margin-right: 40px;
    }
    tbody {
      tr {
        &:first-child {
          td {
            border-top-width: 1px;
          }
        }
        td {
          &:first-child {
            width: 175px;
          }
          .main {
            margin-right: 15px;
          }
        }
      }
    }
  }
}
</style>
