<template>
  <div v-if="voucher">
    <ModalEditOptions
      v-if="showEditOptions"
      :showModal="showEditOptions"
      :onClickCancel="hideModal"
    />

    <ui-section-header :showEdit="locationCanEdit" :editFunction="openOptions">
      <template v-slot:title>{{
        $t('Components.Vouchers.Options.Template_Title')
      }}</template>
    </ui-section-header>

    <table class="table is-striped is-fullwidth">
      <thead>
        <th>{{ $t('Components.Vouchers.Options.Table_Head_Name') }}</th>
        <th>{{ $t('Components.Vouchers.Options.Table_Head_Price') }}</th>
        <th>{{ $t('Components.Vouchers.Options.Table_Head_Percentage') }}</th>
        <th>{{ $t('Components.Vouchers.Options.Table_Head_Action') }}</th>
      </thead>
      <tbody>
        <tr v-for="option in voucher.Options" :key="option.Id">
          <td>
            {{ option.Name }}
          </td>
          <td>
            <span v-if="option.DiscountPercentage > -1">-</span>
            <span v-if="option.DiscountPercentage === -1">{{
              option.DiscountPrice | toCurrency('nl-NL', option.CurrencyIso)
            }}</span>
          </td>
          <td>
            <span v-if="option.DiscountPercentage === -1">-</span>
            <span v-if="option.DiscountPercentage > -1"
              >{{ option.DiscountPercentage }}%</span
            >
          </td>
          <td>
            <span v-if="option.RequiredItem === 0">{{
              $t('Components.Vouchers.Options.Table_Action_Optional')
            }}</span>
            <span v-if="option.RequiredItem === 1">{{
              $t('Components.Vouchers.Options.Table_Action_Pre-selected')
            }}</span>
            <span v-if="option.RequiredItem === 2">{{
              $t('Components.Vouchers.Options.Table_Action_Required')
            }}</span>
            <span v-if="option.RequiredItem === -1">{{
              $t('Components.Vouchers.Options.Table_Action_Remove')
            }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const ModalEditOptions = () => import('@/components/Vouchers/ModalEditOptions')

export default {
  components: {
    ModalEditOptions,
  },

  data() {
    return {
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      locationState: this.$store.getters['locationStore/getLocationState'],
      showEditOptions: false,
    }
  },

  computed: {
    ...mapState('voucherStore', ['voucher']),

    locationCanEdit() {
      let canEdit = false

      if (this.voucher && this.voucher.ChannelId === this.channelId) {
        canEdit = true
      }

      if (this.voucher && this.channelId === 0) {
        let locationIndex = this.voucher.Locations.findIndex(
          (l) => l.LocationId === this.locationId
        )
        if (locationIndex > -1) {
          canEdit = this.voucher.Locations[locationIndex].LocationCreated
        }
      }

      return canEdit
    },
  },

  beforeCreate() {},

  created() {
    if (this.locationId > 0) {
      this.channelId = 0
    }
  },

  methods: {
    openOptions() {
      this.showEditOptions = true
    },
    hideModal() {
      this.showEditOptions = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
