<template>
  <div v-if="mVoucher">
    <section class="section is-small">
      <div class="container">
        <ui-section-header
          :showEdit="locationCanEdit"
          :editFunction="openLocations"
        >
          <template v-slot:title>{{
            $t('Components.Vouchers.Locations.Template_Title')
          }}</template>
        </ui-section-header>
        <message :message="$t('Components.Vouchers.Locations.Error_Text')" />
        <table class="table is-striped is-fullwidth">
          <thead>
            <th>{{ $t('Components.Vouchers.Locations.Table_Head_Name') }}</th>
            <th>{{ $t('Components.Vouchers.Locations.Table_Head_Edit') }}</th>
          </thead>
          <tbody>
            <tr v-for="location in voucher.Locations" :key="location.id">
              <td>{{ location.LocationName }}</td>
              <td>{{ location.LocationCreated }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <modal-edit-locations
      v-if="showEditLocations"
      :showModal="showEditLocations"
      :onClickCancel="hideModal"
    ></modal-edit-locations>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Message from '@/components/UI/Message'
const ModalEditLocation = () =>
  import('@/components/Vouchers/ModalEditLocation')

export default {
  components: {
    message: Message,
    'modal-edit-locations': ModalEditLocation,
  },
  props: {
    voucher: null,
  },

  data() {
    return {
      mVoucher: this.voucher,
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      showEditLocations: false,
    }
  },

  computed: {
    locationCanEdit() {
      let canEdit = false

      if (this.voucher && this.voucher.ChannelId === this.channelId) {
        canEdit = true
      }

      if (this.voucher && this.channelId === 0) {
        let locationIndex = this.voucher.Locations.findIndex(
          (l) => l.LocationId === this.locationId
        )
        if (locationIndex > -1) {
          canEdit = this.voucher.Locations[locationIndex].LocationCreated
        }
      }

      return canEdit
    },
  },

  watch: {},

  beforeCreate() {},

  created() {
    if (this.locationId > 0) {
      this.channelId = 0
    }
  },

  methods: {
    openLocations() {
      this.showEditLocations = true
    },
    hideModal() {
      this.showEditLocations = false
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  tbody {
    tr {
      &:first-child {
        td {
          border-top-width: 1px;
        }
      }
      td {
      }
    }
  }
}
</style>
