<template>
  <div class="">
    <section class="section is-small">
      <nav class="navbar">
        <a
          @click="$router.go(-1)"
          class="navbar-item"
        >
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'chevron-left']" />
          </span>
          <span>Back</span>
        </a>
      </nav>
    </section>

    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="isLoading"
        key="loading"
        class="has-padding has-text-centered"
      >
        <ui-loader />
      </div>

      <div
        v-if="!isLoading"
        key="detailpage"
      >
        <transition
          name="slide-fade"
          mode="out-in"
        >
          <div class="voucher-info">
            <section class="section is-small">
              <BasicData
                :voucher="voucher"
                :voucherId="voucherId"
              />
            </section>

            <section class="section is-small has-margin-bottom">
              <Settings
                :voucher="voucher"
                :voucherId="voucherId"
              />
            </section>

            <section class="section is-small has-margin-bottom">
              <SpacePrice v-if="voucher.Type === 'Package'" />

              <YieldList
                v-if="voucher.Type !== 'Package'"
                :voucherId="voucherId"
                :channelId="channelId"
                :locationId="locationId"
                :yieldList="voucher.Prices"
              />
            </section>

            <section class="section is-small has-margin-bottom">
              <Options :voucherId="voucherId" />
            </section>

            <section
              v-if="voucher.Type !== 'Package'"
              class="section is-small has-margin-bottom"
            >
              <div class="columns">
                <!-- <div class="column">
                    <Companies :voucher="voucher" :voucherId="voucherId" />
                  </div> -->
                <div class="column">
                  <Profiles
                    :voucher="voucher"
                    :voucherId="voucherId"
                  />
                </div>
              </div>
            </section>

            <section class="section is-small has-margin-bottom">
              <div class="columns">
                <div class="column">
                  <Locations
                    :voucher="voucher"
                    :voucherId="voucherId"
                  />
                </div>
                <div class="column"></div>
              </div>
            </section>

            <!-- Publication settings -->

            <section
              v-if="voucher.Type === 'Package'"
              class="section is-small has-margin-bottom"
            >
              <Images
                :voucher="voucher"
                :voucherId="voucherId"
              />
            </section>

            <section
              v-if="voucher.Type === 'Package'"
              class="section is-small has-margin-bottom"
            >
              <Texts
                :voucher="voucher"
                :voucherId="voucherId"
              />
            </section>

            <section
              v-if="voucher.Type === 'Package'"
              class="section is-small has-margin-bottom"
            >
              <Channels
                :voucher="voucher"
                :voucherId="voucherId"
              />
            </section>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import voucherProvider from '@/providers/voucher'
import BasicData from '@/components/Vouchers/BasicData'
import Settings from '@/components/Vouchers/Settings'
import Options from '@/components/Vouchers/Options'
//import Companies from '@/components/Vouchers/Companies'
import Profiles from '@/components/Vouchers/Profiles'
import Locations from '@/components/Vouchers/Locations'
import SpacePrice from '@/components/Vouchers/SpacePrice'
import YieldList from '@/components/Yield/YieldList'

import Channels from '@/components/Vouchers/Channels'
import Texts from '@/components/Vouchers/Texts'
import Images from '@/components/Vouchers/Images'

export default {
  props: {},

  components: {
    BasicData,
    Settings,
    Options,
    //Companies,
    Profiles,
    Locations,
    SpacePrice,
    YieldList,
    Images,
    Channels,
    Texts,
  },

  data() {
    return {
      isLoading: true,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      voucherId: Number(this.$route.params.voucherId),
    }
  },

  computed: {
    // ...mapState('locationStore', ['locationState']),
    ...mapState('channelStore', ['channel']),
    ...mapState('voucherStore', ['voucher']),
  },

  created() {
    // Get voucher data
    this.getVoucher()
  },

  beforeDestroy() {
    this.setVoucher(null)
  },

  methods: {
    ...mapMutations('menuStore', ['setSelectedMenuItemIndex']),
    ...mapMutations('voucherStore', ['setVoucher']),

    /**
     * Get voucher data
     */
    getVoucher() {
      let self = this
      voucherProvider.methods.getVoucherById(this.voucherId, this.locationId)
        .then((response) => {
          if (response.status === 200) {
            self.setVoucher(response.data)
          }
        })
        .catch(() => { })
        .finally(() => {
          self.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
