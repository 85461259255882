<template>
  <div>
    <ModalEditChannels
      v-if="showEditChannels"
      :showModal="showEditChannels"
      :onClickCancel="hideModal"
      :channelId="channelId"
      :locationId="locationId"
    />

    <ui-section-header
      :showEdit="locationCanEdit"
      :editFunction="setShowEditChannels"
    >
      <template v-slot:title>Channels</template>
    </ui-section-header>

    <table class="table is-fullwidth is-narrow">
      <thead>
        <tr>
          <th>{{ $t('Components.Options.OptionChannel.Label_Channel') }}</th>
          <th class="has-text-right">
            {{ $t('Components.Options.OptionChannel.Label_Active') }}
          </th>
          <th class="has-text-right">
            {{ $t('Components.Options.OptionChannel.Label_IsPublic') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="channel in channels" :key="channel.ChannelId">
          <td>
            <span>{{ channel.Name }}</span>
          </td>
          <td width="75" class="has-text-right">
            <span class="icon">
              <font-awesome-icon
                v-if="checkVoucherOnChannel(channel.Id)"
                :icon="['fas', 'check']"
                class="has-text-success"
              />
              <font-awesome-icon
                v-if="!checkVoucherOnChannel(channel.Id)"
                :icon="['fas', 'times']"
                class="has-text-danger"
              />
            </span>
          </td>
          <td width="75" class="has-text-right">
            <font-awesome-icon
              v-if="checkVoucherActiveOnChannel(channel.Id)"
              :icon="['fas', 'check']"
              class="has-text-success"
            />
            <font-awesome-icon
              v-if="!checkVoucherActiveOnChannel(channel.Id)"
              :icon="['fas', 'times']"
              class="has-text-danger"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const ModalEditChannels = () =>
  import('@/components/Vouchers/ModalEditChannels')

export default {
  components: {
    ModalEditChannels,
  },

  props: {},

  data() {
    return {
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      showEditChannels: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationState']),
    ...mapState('voucherStore', ['voucher']),

    locationCanEdit() {
      let canEdit = false

      if (this.voucher && this.voucher.ChannelId === this.channelId) {
        canEdit = true
      }

      if (this.voucher && this.channelId === 0) {
        let locationIndex = this.voucher.Locations.findIndex(
          (l) => l.LocationId === this.locationId
        )
        if (locationIndex > -1) {
          canEdit = this.voucher.Locations[locationIndex].LocationCreated
        }
      }

      return canEdit
    },

    channels() {
      let self = this

      let channels = []

      if (self.channel) {
        channels.push(self.channel)
      }
      return channels
    },
  },

  created() {
    if (this.locationId > 0) {
      this.channelId = 0
    }
  },

  methods: {
    checkVoucherOnChannel(channelId) {
      let result = false

      let channelIndex = this.voucher.Channels.findIndex(
        (c) => c.ChannelId === channelId
      )
      if (channelIndex > -1) {
        result = true
      }

      return result
    },

    checkVoucherActiveOnChannel(channelId) {
      let result = false

      let channelIndex = this.voucher.Channels.findIndex(
        (c) => c.ChannelId === channelId
      )
      if (channelIndex > -1) {
        result = this.voucher.Channels[channelIndex].IsPublic
      }

      return result
    },

    setShowEditChannels() {
      this.showEditChannels = true
    },
    hideModal() {
      this.showEditChannels = false
    },
  },
}
</script>

<style></style>
