<template>
  <div v-if="mVoucher">
    <ModalEditBasicData
      v-if="showEditBasicData"
      :showModal="showEditBasicData"
      :onClickCancel="hideModal"
      :voucher="voucher"
    />

    <ui-page-hero
      additionalTitleClass="no-margin-bottom"
      :title="'Voucher: ' + mVoucher.Name"
    />

    <ui-section-header
      :showEdit="locationCanEdit"
      :editFunction="openBasicData"
    >
      <template v-slot:title>{{
        $t('Components.Vouchers.BasicData.Template_Title')
      }}</template>
    </ui-section-header>

    <table class="table is-striped is-fullwidth">
      <tbody>
        <tr>
          <td>{{ $t('Components.Vouchers.BasicData.Label_Name') }}</td>
          <td>{{ mVoucher.Name }}</td>
        </tr>
        <tr>
          <td>{{ $t('Components.Vouchers.BasicData.Label_Type') }}</td>
          <td>{{ mVoucher.Type }}</td>
        </tr>
        <tr v-if="voucher.Type !== 'Rate' && voucher.Type !== 'Package'">
          <td>{{ $t('Components.Vouchers.BasicData.Label_Code') }}</td>
          <td>{{ mVoucher.Code }}</td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Vouchers.BasicData.Label_Description') }}
          </td>
          <td>{{ mVoucher.Description }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
const ModalEditBasicData = () =>
  import('@/components/Vouchers/ModalEditBasicData')
export default {
  components: {
    ModalEditBasicData,
  },
  props: {
    voucher: null,
  },

  data() {
    return {
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      mVoucher: this.voucher,
      showEditBasicData: false,
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),

    locationCanEdit() {
      let canEdit = false

      if (this.voucher && this.voucher.ChannelId === this.channelId) {
        canEdit = true
      }

      if (this.voucher && this.channelId === 0) {
        let locationIndex = this.voucher.Locations.findIndex(
          (l) => l.LocationId === this.locationId
        )
        if (locationIndex > -1) {
          canEdit = this.voucher.Locations[locationIndex].LocationCreated
        }
      }

      return canEdit
    },
  },

  beforeCreate() {},

  created() {
    if (this.locationId > 0) {
      this.channelId = 0
    }
  },

  methods: {
    openBasicData() {
      this.showEditBasicData = true
    },
    hideModal() {
      this.showEditBasicData = false
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  tbody {
    tr {
      &:first-child {
        td {
          border-top-width: 1px;
        }
      }
      td {
        &:first-child {
          width: 175px;
        }
      }
    }
  }
}
</style>
