<template>
  <div v-if="voucher">
    <ModalEditImage
      v-if="showEditImage"
      :showModal="showEditImage"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showEdit="locationCanEdit"
      :editFunction="setShowEditImage"
      :editLabel="$t('Components.Options.OptionChannel.Link_Edit')"
    >
      <template v-slot:title>{{
        $t('Components.Options.OptionImage.Main_Title')
      }}</template>
    </ui-section-header>

    <div class="imageContainer">
      <ImageViewer
        :aspectRatio="4/3"
        type="location"
        :imageSrc="voucher.Image"
        :itemName="voucher.Name"
        :itemId="this.locationId"
        :imageSize="640"
        class="is-4by3 mb-4"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
const ModalEditImage = () => import('@/components/Vouchers/ModalEditImage')
const ImageViewer = () => import('@/components/UI/Components/ImageViewer.vue')

export default {
  components: {
    ModalEditImage,
    ImageViewer
  },

  data() {
    return {
      showEditImage: false,
      locationId: Number(this.$route.params.locationId),
    }
  },

  computed: {
    ...mapState('voucherStore', ['voucher']),

    locationCanEdit() {
      let canEdit = false

      if (this.voucher) {
        let locationIndex = this.voucher.Locations.findIndex(
          (l) => l.LocationId === this.locationId
        )
        if (locationIndex > -1) {
          canEdit = this.voucher.Locations[locationIndex].LocationCreated
        }
      }
      return canEdit
    },
  },

  methods: {
    setShowEditImage() {
      this.showEditImage = true
    },

    hideModal() {
      this.showEditImage = false
    },
  },
}
</script>

<style lang="sass" scoped>
.imageContainer
  max-width: 640px
</style>